import * as React from 'react';
import { useStyles } from './Style'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import AddParticipantModal from '../AddParticipantModal/AddParticipantModal';
import ParticipantCard from '../ParticipantCard/ParticipantCard';
import ProgressBar from '../ProgressBar/ProgressBar';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import Pic from '../../../Images/admin/picture.png'
import { useDispatch, useSelector } from 'react-redux';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AdminTheme from '../../Common/AdminTheme';
import { PartcipantsAPI, PartcipantsSearchAPI } from '../../../redux/Action/Admin/Partcipants/Partcipants';
import { PartcipantsProgramListingAPI } from '../../../redux/Action/Admin/ParticipantProgramLisiting/ParticipantProgram';
import { PartcipantsActivitesAPI } from '../../../redux/Action/Admin/PartcipantActivites/PartcipantActivities';
import { DeletePartcipantsActivitesAPI } from '../../../redux/Action/Admin/PartcipantActivites/DeletePartcipantActivites';
import { AdminParticipantCheckInExportAPI } from '../../../redux/Action/Admin/SampleExport/SampleExport'

import { EventDashboardAPI } from '../../../redux/Action/Admin/EventList/EventDashboardList';
import { BulkDeleteProgramAPI } from '../../../redux/Action/Admin/ParticipantProgramLisiting/BulkDeleteProgram';
import SearchBar from '../SearchBar/SearchBar';
import { useState } from 'react';
import { useEffect } from 'react';
import AdmLoader from '../AdmLoader/AdmLoader';
import { Clear_ResetPassword_Check } from '../../../redux/Reducer/ResetPassword/ResetPAsswordSlice';
import Snacbar from '../../../Components/Snackbar/Snacbar';
import DeletePopup from '../DeletePopup/DeletePopup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIcon from '@mui/icons-material/Check';
import { BulkcheckinProgramAPI, BulkremovecheckinProgramAPI, SinglecheckinProgramAPI, SinglenotcheckinProgramAPI } from '../../../redux/Action/AdminParticipantCheckIN/AdminParticipantbulkCheckin';
import ParticipantDropdown from '../ParticipantDropdown/ParticipantDropdown';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearIcon from '@mui/icons-material/Clear';
// import { SinglecheckinProgramAPI } from '../../../redux/Action/AdminParticipantCheckIN/AdminSingleCheckIn';
import PrintIcon from '@mui/icons-material/Print';
import { BadgePrinterAPI } from '../../../redux/Action/printerbadge/PrinterBadge';
import printJS from 'print-js';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
function AuthorAction({ eventid }) {
  const [open, setOpen] = React.useState(false);
  const [opendelete, setOpendelete] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const programactivitesID = localStorage.getItem("programactivitesID")
  const PrinterBadgeSlice=useSelector(state=>state?.PrinterBadgeSlice)
  const handleEdit = (e) => {
    // console.log("ee", eventid, open)
    dispatch(PartcipantsActivitesAPI(eventid, programactivitesID))

    setOpen(true)
  }

  const handleDelete = (e) => {
    setOpendelete(true)
  }
  const handleDeleteevent = () => {
    dispatch(DeletePartcipantsActivitesAPI(eventid))
    // eventid
    setOpendelete(false);
  }
  const printBadge = () => {
    // Configure the printJS options
    const printOptions = {
      printable: `${process.env.REACT_APP_BASE_URL}/print-partcipant/${programactivitesID}/${eventid}`,
      type: 'pdf',
      showModal: false
    };

    // Call printJS
    printJS(printOptions);
  };
 
  return (
    <>
      <div className={classes.ActionRow}>
        <IconButton aria-label="badge" id="printBtn" onClick={(e) => printBadge(e)}>
          <PrintIcon />
        </IconButton>
        <IconButton aria-label="edit" onClick={(e) => handleEdit(e)}>
          <EditNoteRoundedIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={(e) => handleDelete(e)}>
          <DeleteIcon />
        </IconButton>
      </div>
      <DeletePopup setOpen={setOpendelete} open={opendelete} handleDelete={() => handleDeleteevent()} />

      <AddParticipantModal open={open} setOpen={setOpen} eventid={eventid} />
    </>
  );
}



function createData(id, authors, checkIn, foodVoucherLeft, gameBooth, progress, action) {
  return {
    id,
    authors,
    checkIn,
    foodVoucherLeft,
    gameBooth,
    progress,
    action,
  };
}

const rows = [
  createData(1,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '1/5',
    '5/5',
    '0/5',
    <ProgressBar Value="25" />,
    <AuthorAction />
  ),
  createData(2,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '2/5',
    '4/5',
    '0/5',
    <ProgressBar Value="25" />,
    <AuthorAction />
  ),
  createData(3,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '3/5',
    '3/5',
    '0/5',
    <ProgressBar Value="100" />,
    <AuthorAction />
  ),
  createData(4,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '4/5',
    '2/5',
    '0/5',
    <ProgressBar Value="50" />,
    <AuthorAction />
  ),
  createData(5,
    <ParticipantCard
      Image={Pic}
      Name="Janice wang"
      Validity="16 Dec, 2024 to 19 Dec, 2024"
    />,
    '5/5',
    '1/5',
    '0/5',
    <ProgressBar Value="90" />,
    <AuthorAction />
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // console.log("array", array)
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const ProgramListingSlice = useSelector(state => state?.ProgramListingSlice)
  const ProgramListing = ProgramListingSlice?.value?.data?.map((data) => ({
    id: data?.title,
    numeric: false,
    label: data?.title,
  }))
  const headCel = [
    {
      id: 'authors',
      numeric: false,
      label: 'Authors',
    },
    {
      id: 'checkIn',
      numeric: true,
      label: 'Check-In',
    },


  ];
  const headCel1 = [
    {
      id: 'action',
      numeric: false,
      label: 'Action',
    },



  ];

  const headCell = ProgramListing ? headCel?.concat(ProgramListing) : headCel
  const headCells = headCell?.concat(headCel1)
  console.log("ProgramListingSlice", ProgramListingSlice)
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
          // sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, Data, selected, page, pagecheck, setPage, setSelected, setChecked, checked } = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const [selectedvalue, setselectedvalue] = React.useState("")
  const ProgramListingSlice = useSelector(state => state?.ProgramListingSlice)
  const [search, setsearch] = useState("");
  const [open, setOpen] = useState(false)
  const [pickupsearchcheck, setpickupsearchcheck] = useState(false)
  const SingleDeleteEventSlice = useSelector(state => state?.SingleDeleteEventSlice)
  const AdminBulkDeleteEventSlice = useSelector(state => state?.AdminBulkDeleteEventSlice)
  const paginationnumber = localStorage.getItem("paginationnumber")
  const programactivitesID = localStorage.getItem("programactivitesID")
  // const [checked, setChecked] = useState(false);
  React.useEffect(() => {
console.log("programactivitesID",programactivitesID)
    if(programactivitesID !=undefined &&programactivitesID !="undefined" && programactivitesID && programactivitesID!=null && programactivitesID!="null"){
      setselectedvalue(+programactivitesID)
      dispatch(PartcipantsProgramListingAPI(programactivitesID))
      dispatch(EventDashboardAPI(programactivitesID))
      localStorage.setItem("participantpage", page + 1)

      dispatch(PartcipantsAPI(programactivitesID, page + 1, paginationnumber))
    }else{

  
    localStorage.setItem("programactivitesID", Data[0]?.id)
    // if(pagecheck==false){
    if (Data[0]?.id != undefined && Data[0]?.id!="undefined" && Data[0]?.id!=null && Data[0]?.id!="null") {
      setselectedvalue(Data[0]?.id)
      dispatch(PartcipantsProgramListingAPI(Data[0]?.id))
      dispatch(EventDashboardAPI(Data[0]?.id))
      localStorage.setItem("participantpage", page + 1)

      dispatch(PartcipantsAPI(Data[0]?.id, page + 1, paginationnumber))
    }
    }
    // }
  }, [Data, AdminBulkDeleteEventSlice, SingleDeleteEventSlice,programactivitesID])
  // React.useEffect(() => {
  //   // setselectedvalue(Data[0]?.id)
  //   // dispatch(PartcipantsProgramListingAPI(Data[0]?.id))
  //   // dispatch(EventDashboardAPI(Data[0]?.id))
  //   // localStorage.setItem("programactivitesID", Data[0]?.id)
  //   if (programactivitesID) {

  //     dispatch(PartcipantsAPI(programactivitesID, page + 1))
  //   }
  // }, [page])

  const handleChange = (event, newValue) => {
    // console.log("Data", Data,newValue)
    setPage(0)
    setselectedvalue(newValue)
    localStorage.setItem("programactivitesID", newValue)
    dispatch(PartcipantsProgramListingAPI(newValue))
    dispatch(EventDashboardAPI(newValue))
    localStorage.setItem("participantpage", 1)

    dispatch(PartcipantsAPI(newValue, 1, paginationnumber))
  }
  const joinselected = selected?.map(String).join(',')
  const handleDelete = () => {
    setOpen(true)
    // const formData = new FormData();
    // formData.append(`participant_ids`, joinselected);
    // dispatch(BulkDeleteProgramAPI(formData, selectedvalue))

  }
  const handlecheckin = () => {
    // setOpen(true)
    const formData = new FormData();
    formData.append(`participant_ids`, joinselected);
    formData.append(`event_id`, selectedvalue);
    // const formData = new FormData();
    // formData.append(`participant_ids`, joinselected);

    dispatch(BulkcheckinProgramAPI(formData, selectedvalue))
    setSelected([])

  }
  const handleBluckRemovecheckin = () => {
    // setOpen(true)
    const formData = new FormData();
    formData.append(`participant_ids`, joinselected);
    formData.append(`event_id`, selectedvalue);
    // const formData = new FormData();
    // formData.append(`participant_ids`, joinselected);

    dispatch(BulkremovecheckinProgramAPI(formData, selectedvalue))
    setSelected([])

  }
  const handleDeleteevent = () => {

    const formData = new FormData();
    formData.append(`participant_ids`, joinselected);
    dispatch(BulkDeleteProgramAPI(formData, selectedvalue))
    setSelected([])

  }
  // React.useEffect(() => {
  //   // PartcipantsSearchAPI
  // }, [search])
  const searchtextbox = () => {
    dispatch(PartcipantsSearchAPI(selectedvalue, search))

  }

  useEffect(() => {
    const delay = 500; // Adjust the debounce delay in milliseconds
    const timerId = setTimeout(() => {
      if (pickupsearchcheck == true) {

        searchtextbox();
      }
    }, delay);

    // Cleanup the timer when the component unmounts or when inputValue changes
    return () => clearTimeout(timerId);
  }, [search]);

  const handleChangecheck = (event) => {
    setChecked(event.target.checked);
    console.log("Checkbox state: ", event.target.checked);
  };
  // console.log("ProgramListingSlice", ProgramListingSlice)


  const handleParticipantCheckExport = (event) => {
    
          if (selectedvalue) {
              dispatch(AdminParticipantCheckInExportAPI(selectedvalue))
          } 
          // AdminSampleExportAPI
      }

      


  return (
    <div className={classes.ParticipantTableHeader}>
      <div className={classes.ParticipantTableHeaderLeft}>
        {numSelected > 0 ? (
          <h4>{numSelected} Selected Participants</h4>
        ) : (
          <h4 id="tableTitle">All Participants</h4>
        )}
      </div>
      
      <div className={classes.ParticipantTableHeaderRight}>
        <div className='rw'>
          <div className='cl'>
            <FormControlLabel
              value="end"
              control={<Checkbox checked={checked} onChange={handleChangecheck} />}
              label="Attendance"
              labelPlacement="end"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  color: AdminTheme.lightColor,
                  fontFamily: AdminTheme.poppins,
                }
              }}
            />
          </div>
          
          <div className='cl'>
            <SearchBar search={search} setsearch={setsearch} setpickupsearchcheck={setpickupsearchcheck} />
          </div>
          <div className='cl'>
            <Select
              placeholder="Select an event"
              indicator={<ArrowDropDownOutlinedIcon />}
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "21px",
                color: "#434349",
                fontFamily: AdminTheme.poppins,
                minHeight: "40px !important",
                height: "40px !important",
                borderRadius: "4px !important",
                background: "#F3F6F9 !important",
                padding: "10px 20px !important",
                border: "none",
                "&::placeholder": {
                  color: "#D6D6E0",
                  fontWeight: "400",
                },
                [`& .${selectClasses.indicator}`]: {
                  transition: '0.2s',
                  [`&.${selectClasses.expanded}`]: {
                    transform: 'rotate(-180deg)',
                  },
                },
              }}
              value={selectedvalue}

              onChange={handleChange}
            >

              {
                Data?.map((data) => {
                  return <Option value={data?.id}>{data?.name}</Option>

                })
              }
            </Select>
          </div>
          <div className='cl'>
            <ButtonPrimary 
            label="Check-In Export"
            disabledcheck={true}
            handleClick={() => handleParticipantCheckExport()}
            />
          </div>
          {numSelected > 0 ? (
            <>
              {/* <div className='cl'>
                <Tooltip title="check-in">
                  <IconButton className={classes.IconBtncheckin} onClick={() => handlecheckin()}>
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              </div> */}
              {/* <div className='cl'>
                <Tooltip title="Delete">
                  <IconButton className={classes.IconBtnDelete} onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div> */}
              <div className='cl'>
                <ParticipantDropdown handleDelete={handleDelete} handleBluckRemovecheckin={handleBluckRemovecheckin} handlecheckin={handlecheckin} selectedvalue={selectedvalue} setSelected={setSelected} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

      </div>
      <DeletePopup setOpen={setOpen} open={open} handleDelete={() => {
        handleDeleteevent()
        setOpen(false);
      }} />
    </div>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function ParticipantsTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('checkIn');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pagecheck, setPagecheck] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const AdminEventListSlice = useSelector(state => state?.AdminEventListSlice)
  const PartcipantSlice = useSelector(state => state?.PartcipantSlice)
  const ResetPasswordSlice = useSelector(state => state?.ResetPasswordSlice)
  const [success, setsuccess] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [Data, setdata] = React.useState([])
  const [Datalength, setdatalength] = React.useState([])
  const [Datas, setdatas] = React.useState([])
  const dispatch = useDispatch()
  const programactivitesID = localStorage.getItem("programactivitesID")
  const SingleDeleteEventSlice = useSelector(state => state?.SingleDeleteEventSlice)
  const AdminBulkDeleteEventSlice = useSelector(state => state?.AdminBulkDeleteEventSlice)
  const BulkDeleteParticipantSlice = useSelector(state => state?.BulkDeleteParticipantSlice)

  const ReloadSlice = useSelector(state => state?.ReloadSlice?.value)
  const [checked, setChecked] = useState(false);
  const paginationnumber = localStorage.getItem("paginationnumber")
  React.useEffect(() => {
    if (PartcipantSlice?.value?.data?.user != undefined) {
      // console.log("adminEvent", PartcipantSlice)
      if (checked == true) {
        const particpantfilter = PartcipantSlice?.value?.data?.user?.filter((data) => data?.attend == 1)
        setdata(particpantfilter)
      } else {

        setdata(PartcipantSlice?.value?.data?.user)
      }
      setdatalength(PartcipantSlice?.value?.data)
    } else if (PartcipantSlice?.error?.message) {
      setdata([])
      setdatalength([])
    }
  }, [PartcipantSlice, checked])
  // console.log("Datalength?.pagination?.count", Data)
  React.useEffect(() => {
    if (AdminEventListSlice?.value?.data != undefined) {
      // console.log("adminEvent", AdminEventListSlice)
      setdatas(AdminEventListSlice?.value?.data)
    }
  }, [AdminEventListSlice])
  React.useEffect(() => {
    // setselectedvalue(Data[0]?.id)
    // dispatch(PartcipantsProgramListingAPI(Data[0]?.id))
    // dispatch(EventDashboardAPI(Data[0]?.id))
    // localStorage.setItem("programactivitesID", Data[0]?.id)
    if (programactivitesID && programactivitesID !== null && programactivitesID !== undefined && programactivitesID !== "undefined") {
      localStorage.setItem("participantpage", page + 1)
      dispatch(PartcipantsAPI(programactivitesID, page + 1, paginationnumber))
    }
  }, [page, programactivitesID])

  // console.log("PartcipantSlice", PartcipantSlice)

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = PartcipantSlice?.value?.data?.user?.map((n) => n.user_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    console.log("vikalpsearch")
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    console.log("vikalpsearch", newSelected)
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagecheck(true)
    setSelected([])
  };
  useEffect(() => {
    localStorage.setItem("paginationnumber", 20)

  }, [])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("paginationnumber", event.target.value)
    dispatch(PartcipantsAPI(programactivitesID, 0, event.target.value))
    setPage(0);
    setPagecheck(true)
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  console.log("isSelected", isSelected)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(Data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, Data],
  );

  const classes = useStyles();
  useEffect(() => {
    if (ResetPasswordSlice?.value?.message) {

      setsuccess(true);
      // setOpentrue(false);

      // setnewPassworderror("")
      // setConfirmPassword("")
      setTimeout(() => {
        dispatch(Clear_ResetPassword_Check())
      }, 3000)
    }
  }, [ResetPasswordSlice])
  const handlecheckinSingle = (data) => {
    // console.log("vikalpppppppp")
    dispatch(SinglecheckinProgramAPI(data))
  }
  const handlenotcheckinSingle = (data) => {
    // console.log("vikalpppppppp")
    dispatch(SinglenotcheckinProgramAPI(data))
  }


  // console.log("PartcipantSlice?.value?.data?.user?", Data, visibleRows)
  return (
    <>
      {
        PartcipantSlice?.loader == true && ReloadSlice == true && (
          <AdmLoader />
        )
      }
      {
        BulkDeleteParticipantSlice?.loading == true && (
          <AdmLoader />
        )
      }
      {
        (ResetPasswordSlice?.value?.message) && (
          <Snacbar
            open={success}
            setOpen={setsuccess}
            messagetype={"success"}
            message={(ResetPasswordSlice?.value?.message)}
          />

        )
      }
      <div className={classes.ParticipantTable}>
        <EnhancedTableToolbar numSelected={selected.length} Data={Datas} selected={selected} page={page} setPage={setPage} pagecheck={pagecheck} setSelected={setSelected} setChecked={setChecked} checked={checked} />
        {Data?.length > 0 ? (
          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={PartcipantSlice?.value?.data?.user?.length}
                />
                <TableBody>
                  {Data?.map((row, index) => {
                    const isItemSelected = isSelected(row.user_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover

                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.user_id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox"
                          onClick={(event) => handleClick(event, row.user_id)}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          {row?.name}
                        </TableCell>
                        <TableCell align="center" >
                          {row?.attend != 1 && <ClearIcon sx={{ color: AdminTheme.dangerColor }} onClick={() => { handlecheckinSingle(row.user_id) }} />}
                          {/* <CheckIcon sx={{ color: AdminTheme.lightColor }} /> */}
                          {row?.attend == 1 && <DoneAllIcon sx={{ color: AdminTheme.successColor }} onClick={() => { handlenotcheckinSingle(row.user_id) }} />}
                        </TableCell>
                        {/* <TableCell align="center">{row?.checked_in}</TableCell> */}
                        {
                          row?.programs?.map((data) => {

                            return <TableCell align="center">{+data?.total_can_use - +data?.total_used}/{data?.total_can_use}</TableCell>
                          })
                        }

                        <TableCell align="center"><AuthorAction eventid={row?.user_id} /></TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 30, 40, 50]}
              component="div"
              count={Datalength?.pagination?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </>
        ) : <>
          <div className={classes.PlaceHolder}>
            No Participant
          </div>
        </>}
      </div>
    </>
  );
}