import axios from "axios";
import { APIURL } from "../../../../APIHandler/APIURL"
import { Headers } from "../../../../APIHandler/HeaderAPI";
import { reloadHandler } from "../../../../utlis/utlis";
import { Admin_Sample_Export_failure, Admin_Sample_Export_request, Admin_Sample_Export_success } from "../../../Reducer/Admin/Sampleexport/SampleExportSlice";

export const AdminSampleExportAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Sample_Export_request())
    try {
        const { data } = await axios.get(`${APIURL.adminsampleexport}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        downloadCSV(data)
        dispatch(Admin_Sample_Export_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Sample_Export_failure(error?.response?.status))
    }
}

const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Sample-Import-file.csv');
    document.body.appendChild(link);
  
    link.click();
  
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
export const AdminParticipantExportAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Sample_Export_request())
    try {
        const { data } = await axios.get(`${APIURL.participantexport}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        downloadParticipantCSV(data)
        dispatch(Admin_Sample_Export_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Sample_Export_failure(error?.response?.status))
    }
}


export const AdminParticipantCheckInExportAPI = (payload) => async (dispatch, getState) => {
    // const eventItemId=localStorage.getItem("eventItemId")
    const auth_token = localStorage.getItem("auth_token")
    dispatch(Admin_Sample_Export_request())
    try {
        const { data } = await axios.get(`${APIURL.participantCheckInexport}/${payload}`, {
            headers: {
                "host-url": Headers?.["host-url"],
                "language": Headers?.language,
              "event-slug":Headers?.["event-slug"],
                "Content-type": "application/json",
                Authorization: `Bearer ${auth_token}`
            },
        })
        downloadParticipantCSV(data,'check-in')
        dispatch(Admin_Sample_Export_success(data))
    } catch (error) {
        reloadHandler(error?.response?.status)
        dispatch(Admin_Sample_Export_failure(error?.response?.status))
    }
}

const downloadParticipantCSV = (csvData,type='') => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    if(type){
        link.setAttribute('download', 'CheckIn-Participant-Export-file.csv');
    }else{
        link.setAttribute('download', 'Participant-Export-file.csv');
    }
    document.body.appendChild(link);
  
    link.click();
  
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  