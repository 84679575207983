import { Hipster_Base_URL } from "./HeaderAPI";
import { Hipster_Base_URL_2 } from "./HeaderAPI";

export const APIURL = {
    v1: `${Hipster_Base_URL}`,
    emailCheck: `${Hipster_Base_URL}/user/check-event`,
    login: `${Hipster_Base_URL}/user/signin`,
    eventdetails: `${Hipster_Base_URL}/user/event-detail`,
    vouchers: `${Hipster_Base_URL}/user/event-program`,
    programdetails: `${Hipster_Base_URL}/user/event/program`,
    scanactivity: `${Hipster_Base_URL}/user/scan-activity`,
    qrprogramgenerate: `${Hipster_Base_URL}/user/program-qr-generate`,
    forgotpassword: `${Hipster_Base_URL}/user/forget-password`,
    setpassword: `${Hipster_Base_URL}/user/set-password`,
    changepassword: `${Hipster_Base_URL}/user/change-password`,
    resetpassword: `${Hipster_Base_URL}/user/reset-user-password`,
    attendancecheck: `${Hipster_Base_URL}/user/check-participant-attendence`,




    //staff


    Staffprogram: `${Hipster_Base_URL}/staff/programs`,
    qrstaffgenerate: `${Hipster_Base_URL}/staff/program-qr-generate`,


    //admin

    adminsampleexport: `${Hipster_Base_URL}/admin/sample-export`,
    adminsampleimport: `${Hipster_Base_URL}/admin/event-users-import`,
    admincreateevent: `${Hipster_Base_URL}/admin/events`,
    admineventlist: `${Hipster_Base_URL}/admin/events`,
    admineventdeletelist: `${Hipster_Base_URL}/admin/event`,
    adminbulkdeleteevent: `${Hipster_Base_URL}/admin/bulk-event-delete`,
    getediteventlist: `${Hipster_Base_URL}/admin/event`,
    editeventlist: `${Hipster_Base_URL}/admin/event`,
    admincreateactivity: `${Hipster_Base_URL}/admin/activites`,
    admingeteditactivity: `${Hipster_Base_URL}/admin/activity`,
    admindeleteactivity: `${Hipster_Base_URL}/admin/activity`,
    createspeaker: `${Hipster_Base_URL}/admin/speakers`,
    getspeakers: `${Hipster_Base_URL}/admin/speakers`,
    getidspeakers: `${Hipster_Base_URL}/admin/speaker`,
    Editspeakers: `${Hipster_Base_URL}/admin/speaker`,
    getActivites: `${Hipster_Base_URL}/admin/activites`,
    partcipants: `${Hipster_Base_URL}/admin/partcipants`,
    partcipantsactivities: `${Hipster_Base_URL}/admin/partcipant`,
    deletepartcipantsactivities: `${Hipster_Base_URL}/admin/partcipant`,
    partcipantsprogram: `${Hipster_Base_URL}/admin/activites`,
    Addpartcipantactivities: `${Hipster_Base_URL}/admin/add-participant-activity`,
    increaseparticipantquantity: `${Hipster_Base_URL}/admin/increase-participant-quantity`,
    dashboardlist: `${Hipster_Base_URL}/admin/dashboard-activites`,
    deleterogram: `${Hipster_Base_URL}/admin/delete-user-program`,
    bulkdeleteparticipants: `${Hipster_Base_URL}/admin/bulk-participants-delete`,
    addadminstaff: `${Hipster_Base_URL}/admin/staff`,
    editgetadminstaff: `${Hipster_Base_URL}/admin/get-staff`,
    rearrangeactivity: `${Hipster_Base_URL}/admin/update-program-order`,
    archive: `${Hipster_Base_URL}/admin/archived_events`,
    eventManager: `${Hipster_Base_URL}/admin/event-manager`,
    duplicateevent: `${Hipster_Base_URL}/admin/duplicate-event`,
    attendancestaff: `${Hipster_Base_URL}/staff/show-event-qr`,
    userattendance: `${Hipster_Base_URL}/user/scanEventForAttendence`,
    updatebanner: `${Hipster_Base_URL}/admin/update-event-banner-url`,
    deletebanner: `${Hipster_Base_URL}/admin/delete-event-banner`,
    deleteprogrambanner: `${Hipster_Base_URL}/admin/delete-program-banner`,
    participantexport: `${Hipster_Base_URL}/admin/export-partcipants`,
    participantCheckInexport: `${Hipster_Base_URL}/admin/export-checkin-partcipants`,
    bulkcheckinexport: `${Hipster_Base_URL}/admin/bulk-checkin-event`,
    singlecheckinadmin: `${Hipster_Base_URL}/admin/check-in`,
    singlenotcheckinadmin: `${Hipster_Base_URL}/admin/remove-check-in`,
    bulkremovecheckinadmin: `${Hipster_Base_URL}/admin/remove-bulk-checkin-event`,
    eventslug: `${Hipster_Base_URL}/admin/check-event-slug`,
    restoreevent: `${Hipster_Base_URL}/admin/restore-event`,
    badgeprinter: `${Hipster_Base_URL_2}/print-partcipant`,
    deleteloginbanner: `${Hipster_Base_URL}/admin/delete-login-banner`,
    deletearchiveevent: `${Hipster_Base_URL}/admin/delete-archive-event`,



}